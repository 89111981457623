import { FC } from 'react'
import { useNavigateTo } from '../../hooks/useNavigateTo'
import { ICase } from '../../types'
import styles from './case.module.scss'

interface ICasesProps {
	_case: ICase
}

const Case: FC<ICasesProps> = ({ _case }) => {
	const navigateTo = useNavigateTo();
	return (
		<div className={styles.container}>
			<div>
				<h3>{_case.title}</h3>
				<h4>{_case.subtitle}</h4>
				<img className={styles.mobileImg} src={_case.images[0]} />
				<div className={styles.tags}>{_case.tags.map(tag => (
					<p>{tag}</p>
				))}</div>
				<button
					className='blueButton' onClick={() => {
					navigateTo(_case.link)
				}}>Просмотреть кейс
				</button>
			</div>
			<div> {_case.images.map(image => (
				<div><img src={image} alt='' /></div>
			))}
			</div>
		</div>
	)
}

export default Case