import styles from '../common/commonblock2.module.css'

const Awards = () => {
	return (
		<div className={styles.container}>
			<h2 className='block-title'>НАГРАДЫ</h2>
			<section>
				<div className={styles.checkmark}>
					<div></div>
					<img src='/Pictures/services/award.svg' />
				</div>
				<div className={styles.textContainer}>
					<h3>Лучшие проекты НТИ в 2022 и 2023 годах</h3>
				</div>
			</section>
			<section>
				<div className={styles.checkmark}>
					<div></div>
					<img src='/Pictures/services/award.svg' />
				</div>
				<div className={styles.textContainer}>
					<h3>StartUp Tour</h3>
				</div>
			</section>
			<section>
				<div className={styles.checkmark}>
					<div></div>
					<img src='/Pictures/services/award.svg' />
				</div>
				<div className={styles.textContainer}>
					<h3>Лучшая ИТ-компания Пермского Края</h3>
				</div>
			</section>
		</div>
	)
}
export default Awards