const services = [
    { id: 1, name: "ПРОГРАММНОЕ ОБЕСПЕЧЕНИЕ" },
    { id: 2, name: "МОБИЛЬНАЯ РАЗРАБОТКА" },
    { id: 3, name: "РАЗРАБОТКА ВЕБ\u2011СЕРВИСОВ" },
];
export enum Type {
    Software,
    Web,
    Mobile,
}
export default services;