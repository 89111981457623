import styles from './questions.module.css'
import popup from '../../common/popup.module.css'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import InputMask from 'react-input-mask'

type FormData = {
	name: string;
	phone: string;
	email: string;
	subject: string;
	message: string;
	files: FileList;
	agree: boolean;
};
const Questions = () => {
	const { register, handleSubmit, formState: { errors }, reset } = useForm<FormData>()
	const [selectedFiles, setSelectedFiles] = useState<File[]>([])
	const [modalOpen, setModalOpen] = useState(false)
	const [popupAgree, setPopupAgree] = useState(false)
	const [agree, setAgree] = useState(false);
	const token = process.env.REACT_APP_TELEGRAM_TOKEN
	const onSubmit = async (data: FormData) => {
		const formData = new FormData()
		formData.append('Имя', data.name)
		formData.append('Телефон', data.phone)
		formData.append('e-mail', data.email)
		formData.append('Тема обращения', data.subject)
		formData.append('Текст сообщения', data.message)
		for (let i = 0; i < data.files.length; i++) {
			formData.append('files', data.files[i])
		}
		try {
			await axios.post(`https://api.telegram.org/bot${token}/sendMessage`, {
				chat_id: '-4227583515',
				text: `💻 Заявка на разработку:\nИмя: ${data.name}\nТелефон: ${data.phone}\ne-mail: ${data.email}\nТема обращения: ${data.subject}\nТекст сообщения: ${data.message}`
			})
			
			for (let i = 0; i < selectedFiles.length; i++) {
				const fileData = new FormData()
				fileData.append('chat_id', '-4227583515')
				fileData.append('document', selectedFiles[i])
				await axios.post(`https://api.telegram.org/bot${token}/sendDocument`, fileData)
			}
			
			reset()
			setAgree(false)
			setSelectedFiles([])
			alert('Заявка успешно отправлена!')
		} catch (error) {
			console.error('Ошибка отправки:', error)
			alert('Ошибка отправки сообщения!')
		}
	}
	const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			const files = Array.from(e.target.files)
			const validTypes = ['image/jpeg', 'image/png', 'application/msword', 'application/vnd.ms-excel', 'application/pdf']
			const maxSize = 24 * 1024 * 1024 // 24 MB in bytes
			let totalSize = 0
			const validFiles = files.filter(file => {
				totalSize += file.size
				return validTypes.includes(file.type) && totalSize <= maxSize
			})
			if (validFiles.length > 3) {
				alert('Максимум 3 файла.')
				setSelectedFiles(validFiles.slice(0, 3))
			} else {
				setSelectedFiles(validFiles)
			}
		}
	}
	const handleTelegramClick = () => {
		window.open('https://t.me/ooo_vebrazrabotka', '_blank')
	}
	const handlePhoneClick = () => {
		window.location.href = 'tel:+79956683584'
	}
	return (
		<>
			<div className={styles.container}>
				<h2 className='block-title' id='questions'>ОСТАЛИСЬ ВОПРОСЫ?</h2>
				<h3>Закажите <b>бесплатную</b> консультацию!</h3>
				<p className={styles.text}>
					Проконсультируем по разработке индивидуальных
					<b> веб-сервисов, мобильных приложений, ERP/CRM для вашего бизнеса</b>
					, сразу, на первом созвоне.<br />Бесплатно и без обязательств.
				</p>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className={styles.twoColumns}>
						<div>
							<input placeholder='Как к вам обращаться?' {...register('name', { required: true })} /> {errors.name &&
							<span className={styles.errorMessage}>Укажите ваше имя</span>}
						</div>
						<div>
							<InputMask
								mask='+7 (999) 999-99-99'
								placeholder='+7 (000) 000-00-00'
								id='phone-number' {...register('phone', {
								required: true,
								pattern: /^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/
							})} /> {errors.phone && <span className={styles.errorMessage}>Укажите корректный номер телефона</span>}
						</div>
					</div>
					<div className={styles.twoColumns}>
						<div>
							<input
								placeholder='Ваша почта'
								type='email'								{...register('email', { required: true })}
							/> {errors.email && <span className={styles.errorMessage}>Укажите адрес электронной почты</span>}
						</div>
						<div>
							<input
								placeholder='Тема обращения'								{...register('subject', { required: true })}
							/> {errors.subject && <span className={styles.errorMessage}>Укажите тему вашего обращения</span>}
						</div>
					</div>
					<textarea
						className={styles.message}
						placeholder='Сообщение'
						maxLength={500}						{...register('message', { required: true })}					></textarea> {errors.message &&
					<span className={styles.errorMessage}>Расскажите о вашей идее минимум в нескольких предложениях</span>}
					<div className={`${styles.twoColumns}`}>
						<div className={styles.ps}>
							<h4>**О чем писать в сообщении</h4>
							<p>
								Расскажите, чем занимается ваша компания, какие задачи должен решать цифровой продукт, на какие сроки и
								бюджет рассчитываете
							</p>
						</div>
						<div className={styles.fileInputWrapper}>
							<input
								type='file'
								className={styles.fileInput}
								id='file-input'                {...register('files')}
								multiple
								onChange={handleFileChange}
							/>
							<div>
								<label htmlFor='file-input' className={styles.fileInputLabel}> <img src='/Pictures/papersclip.svg' />
									<span>Прикрепить файл</span> </label> {selectedFiles.length > 0 && (
								<div className={styles.selectedFiles}>
									{selectedFiles.map((file, index) => (
										<div key={index}>{file.name}</div>
									))}
								</div>
							)}
							</div>
							<p>**Максимум 3 файла (.jpg, .png, .doc, .xls, .pdf), не более 24 МБ в сумме</p>
						</div>
					</div>
					<div className={styles.twoColumns}>
						<button type='submit' className={agree ? "blueButton" : ''}>Отправить</button>
						<div className={styles.confidential}>
							<p>Согласен на обработку своих персональных данных в соответствии с <span
								onClick={() => {
									window.open('https://вр-ит.рф/ПОЛИТИКА_КОНФИДЕНЦИАЛЬНОСТИ.pdf')
								}}>Политикой конфиденциальности</span>.
							</p>
							<label className={styles.fancyCheckbox}> <input
								type='checkbox'
								className={styles.c} {...register('agree', { required: true })} onClick={() => {
								setAgree(!agree)
							}} />
								<div>
									<div><img src='/Pictures/checkmark.png' /></div>
								</div>
							</label>
						</div>
					</div>
				</form>
				<h3>Вы можете задать<b> любой вопрос </b>в мессенджере или <b> по телефону</b></h3>
				<div className={styles.connection}>
					<div className={styles.tg} onClick={handleTelegramClick}>
						<span>Связаться через <b>Telegram</b></span> <img src='/Pictures/tg-icon.svg' alt='' />
					</div>
					<div className={styles.connectionPhone} onClick={() => setModalOpen(true)}>
						<p className={styles.phone}>+7 (995) 668-35-84</p>
						<p className={styles.callback}>Заказать <b>обратный звонок</b></p>
					</div>
				</div>
			</div>
			{modalOpen && (
				<div className={popup.container}>
					<div className={popup.content}>
						<img src='/Pictures/popup-close.png' className={popup.close} onClick={() => setModalOpen(false)} />
						<h1>Заявка на обратный звонок</h1>
						<form>
							<div>
								<input
									type='text'
									placeholder='Ваше имя'
									{...register('name', { required: true })}
								/>
							</div>
							<div>
								<InputMask
									mask='+7 (999) 999-99-99' placeholder='+7 (000) 000-00-00'
									{...register('phone', { required: true, pattern: /^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/ })}
								/>
							</div>
							<button type='submit' className={popupAgree ? popup.active : ''}>Отправить</button>
							<div className={popup.confidential}>
								<p>Согласен на обработку своих персональных данных в соответствии
									с <span onClick={() => {window.open('https://вр-ит.рф/ПОЛИТИКА_КОНФИДЕНЦИАЛЬНОСТИ.pdf')}}>
										Политикой конфиденциальности</span>.
								</p>
								<label className={popup.fancyCheckbox}>
									<input
										type='checkbox'
										className={popup.c}
										onClick={() => {
											setPopupAgree(!popupAgree)
										}} />
									<div>
										<div><img src='/Pictures/checkmark.png' /></div>
									</div>
								</label>
							</div>
						</form>
					</div>
				</div>
			)}
		</>
	)
}

export default Questions
