import axios from 'axios'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import InputMask from 'react-input-mask'
import popup from '../../common/popup.module.css'
import { useGetWindowWidth } from '../../hooks/useGetWindowSize'
import styles from './vacancies.module.scss'
import vacancies from '../../mock/vacancies'

const Vacancies = () => {
	const [showAll, setShowAll] = useState(false)
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [selectedVacancy, setSelectedVacancy] = useState<string | null>(null)
	const [agree, setAgree] = useState(false)
	const { windowWidth } = useGetWindowWidth()
	const toggleShowAll = () => {
		setShowAll(!showAll)
	}
	const openModal = ({ vacancyName }: { vacancyName: string }) => {
		setSelectedVacancy(vacancyName)
		setIsModalOpen(true)
	}
	const closeModal = () => {
		setIsModalOpen(false)
		setSelectedVacancy(null)
	}
	const handleModalClick = (event: React.MouseEvent<HTMLDivElement>) => {
		if ((event.target as HTMLDivElement).className === 'modal') {
			closeModal()
		}
	}
	type FormData = {
		name: string;
		phone: string;
		post: string;
		username: string;
		agree: boolean;
	};
	const token = process.env.REACT_APP_TELEGRAM_TOKEN
	const { register, handleSubmit, formState: { errors }, reset } = useForm<FormData>()
	const [selectedFiles, setSelectedFiles] = useState<File[]>([])
	const onSubmit = async (data: FormData) => {
		try {
			await axios.post(`https://api.telegram.org/bot${token}/sendMessage`, {
				chat_id: '-4227583515',
				text: `👨 ‍Отклик на вакансию '${selectedVacancy}': \nИмя: ${data.name}\nТелефон: ${data.phone}\nИмя telegram: @${data.username}`
			})
			alert('Ваш отклик был успешно отправлен!')
			reset()
			setAgree(false)
			closeModal()
		} catch (error) {
			alert('Ошибка при отправке отклика. Попробуйте еще раз.')
		}
	}
	const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			const files = Array.from(e.target.files)
			const validTypes = ['image/jpeg', 'image/png', 'application/msword', 'application/vnd.ms-excel', 'application/pdf']
			const maxSize = 24 * 1024 * 1024 // 24 MB in bytes
			let totalSize = 0
			const validFiles = files.filter(file => {
				totalSize += file.size
				return validTypes.includes(file.type) && totalSize <= maxSize
			})
			if (validFiles.length > 3) {
				alert('Максимум 3 файла.')
				setSelectedFiles(validFiles.slice(0, 3))
			} else {
				setSelectedFiles(validFiles)
			}
		}
	}
	return (
		<>
			<div className={styles.container}>
				<h2 className='block-title' id="vacancies">Вакансии</h2>
				<div className={styles.vacancies}>
					{vacancies.slice(0, showAll || windowWidth > 425 ? vacancies.length : 1).map((vacancy) => (
						<div className={styles.vacancy}>
							<p className={styles.logo}>ВР</p>
							<h3>{vacancy.name}</h3>
							<h4>{vacancy.stack}</h4>
							<button
								className='blueButton'
								onClick={() => openModal({ vacancyName: vacancy.name })}>
								Присоединиться к команде
							</button>
							<p className={styles.hashtag}>#ВебРазработка</p>
						</div>
					))}
				</div>
				<button className={`${styles.toggleButton} blueButton`} onClick={toggleShowAll}>
					{showAll ? 'Свернуть' : 'Смотреть все вакансии'}
				</button>
			</div>
			{isModalOpen && (
				<div className={popup.container} onClick={handleModalClick}>
					<div className={popup.content}>
						<img src='/Pictures/popup-close.png' className={popup.close} onClick={() => setIsModalOpen(false)} />
						<h1>Отклик на вакансию '{selectedVacancy}'</h1>
						<form onSubmit={handleSubmit(onSubmit)}>
							<div>
								<input
									type='text'
									placeholder='Ваше имя'									{...register('name', { required: true })}
								/> {errors.name && <span className={popup.errorMessage}>Пожалуйста, введите ваше имя</span>}
							</div>
							<div>
								<InputMask
									mask='+7 (999) 999-99-99'
									placeholder='+7 (000) 000-00-00'									{...register('phone', {
									required: true,
									pattern: /^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/
								})}
								/> {errors.phone &&
								<span className={popup.errorMessage}>Пожалуйста, введите ваш номер телефона</span>}
							</div>
							<div>
								<input
									type='text'
									placeholder='Ваш Telegram username (без @)'									{...register('username', {
									required: false,
									pattern: /^[A-Za-z0-9_]*$/
								})}
								/> {errors.username &&
								<span className={popup.errorMessage}>Пожалуйста, введите ваш Telegram username без @</span>} <input
								type='hidden'
								value={selectedVacancy || ''}									{...register('post', { required: true })}
							/>
							</div>
							<div className={popup.fileInputWrapper}>
								<input
									type='file'
									className={popup.fileInput}
									id='file-input'
									multiple
									onChange={handleFileChange}
								/> <label htmlFor='file-input' className={popup.fileInputLabel}> <img src='/Pictures/papersclip.svg' />
								<span>Прикрепить файл</span> </label>
								<div className={popup.selectedFiles}>
									{selectedFiles.map((file, index) => (
										<div key={index} className={popup.fileName}>{file.name}</div>
									))}
								</div>
								<p>**Максимум 3 файла (.jpg, .png, .doc, .xls, .pdf), не более 24 МБ в сумме</p>
							</div>
							<button type='submit' className={agree ? popup.active : ''}>Отправить</button>
							<div className={popup.confidential}>
								<p>Согласен на обработку своих персональных данных в соответствии с <span
									onClick={() => {
										window.open('https://вр-ит.рф/ПОЛИТИКА_КОНФИДЕНЦИАЛЬНОСТИ.pdf')
									}}>Политикой
										конфиденциальности</span>.
								</p>
								<label className={popup.fancyCheckbox}> <input
									type='checkbox'
									className={popup.c} {...register('agree', { required: true })}
									onClick={() => {
										setAgree(!agree)
									}} />
									<div>
										<div><img src='/Pictures/checkmark.png' /></div>
									</div>
								</label>
							</div>
						</form>
					</div>
				</div>
			)}
		</>
	)
}

export default Vacancies