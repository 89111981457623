import React, { useContext, useEffect, useRef, useState } from 'react'
import styles from './header.module.css'
import { useNavigate } from 'react-router-dom'
import { QuestionsContext } from '../../App'

const Header = () => {
	const navigate = useNavigate()
	const servicesRef = useRef<HTMLDivElement>(null);
	const productsRef = useRef<HTMLDivElement>(null);
	const [isServicesModalVisible, setServicesModalVisible] = useState(false)
	const [isProductsModalVisible, setProductsModalVisible] = useState(false)
	const [isMenuOpen, setIsMenuOpen] = useState(false)
	const [menuProd, setMenuProd] = useState(false)
	const [menuServ, setMenuServ] = useState(false)
	const setQuestionsModalVisible = useContext(QuestionsContext)
	const handleToggleServicesModal = () => {
		setServicesModalVisible(!isServicesModalVisible)
		if (isProductsModalVisible) {
			setProductsModalVisible(false)
		}
	}
	const handleToggleProductsModal = () => {
		setProductsModalVisible(!isProductsModalVisible)
		if (isServicesModalVisible) {
			setServicesModalVisible(false)
		}
	}
	const handleToggleServMenu = () => {
		setMenuServ(!menuServ)
		if (menuServ) {
			setMenuServ(false)
		}
	}
	const handleToggleProdMenu = () => {
		setMenuProd(!menuProd)
		if (menuProd) {
			setMenuProd(false)
		}
	}
	const handleToggleMenu = () => {
		setIsMenuOpen(!isMenuOpen)
	}
	const scrollTo = (elementId: string): boolean => {
		const block = document.getElementById(elementId)
		if (block) {
			const elementPosition = block.getBoundingClientRect().top + window.pageYOffset
			const offsetPosition = elementPosition - 100
			window.scrollTo({
				top: offsetPosition,
				behavior: 'smooth'
			})
			setIsMenuOpen(false)
			return true
		}
		return false
	}
	useEffect(() => {
		if (isMenuOpen) {
			document.body.classList.add('no-scroll')
		} else {
			document.body.classList.remove('no-scroll')
		}
	}, [isMenuOpen])
	const handleQuestionsClick = () => {
		if (!scrollTo('questions')) {
			setQuestionsModalVisible(true)
			setIsMenuOpen(false)
		}
	}
	const navigateTo = (where: string) => {
		navigate(where)
		if (isMenuOpen) {
			setIsMenuOpen(false)
		}
		window.scroll(0, 0)
	}
	const handleClickOutside = (event: MouseEvent) => {
		if (servicesRef.current &&
			!servicesRef.current.contains(event.target as Node) &&
			productsRef.current &&
			!productsRef.current.contains(event.target as Node)) {
			setServicesModalVisible(false);
			setProductsModalVisible(false);
		}
	};
	useEffect(() => {
		document.addEventListener('click', handleClickOutside);
		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, []);
	return (
		<>
			<div className={styles.header}>
				<div className={styles.intoHeader}>
					<div className={styles.leftPart}>
						<div
							className={`${styles.logo} ${styles.mini}`} onClick={() => {
							navigateTo('/')
						}}>
							<b style={{ color: '#5CC3FD' }}>В</b><b>Р</b>
						</div>
						<div
							className={styles.link} onClick={() => {
							navigateTo('/about')
						}}>О КОМПАНИИ
						</div>
						<div
							className={styles.link}
							onClick={handleToggleServicesModal}
							id={styles['services']}
							ref={servicesRef}
						>
							УСЛУГИ
							<img
								src='/Pictures/Arrow.svg'
								className={`${styles.arrow} ${isServicesModalVisible ? styles.rotated : ''}`}
								id='services-arrow'></img>
							<div className={`${styles.modalContainer} ${isServicesModalVisible ? styles.active : ''}`}>
									<p onClick={() => navigateTo('/services/software')}>Разработка ПО</p>
									<p onClick={() => navigateTo('/services/web-services')}>Разработка веб-сервисов</p>
									<p onClick={() => navigateTo('/services/mobile-apps')}>Разработка мобильных
										приложений</p>
							</div>
						</div>
						<div
							className={styles.link}
							onClick={handleToggleProductsModal}
							id={styles['products']}
							ref={productsRef}
						>
							ПРОДУКТЫ
							<img
								src='/Pictures/Arrow.svg'
								className={`${styles.arrow} ${isProductsModalVisible ? styles.rotated : ''}`}
								id='products-arrow'></img>
							<div className={`${styles.modalContainer} ${isProductsModalVisible ? styles.active : ''}`}>
								{/* <p>Крипт.Клауд</p> */}
								<p
									onClick={() => {
										window.open('https://вр-кп.рф')
									}}>КП-генератор</p>
							</div>
						</div>
						<div className={styles.link} onClick={() => scrollTo('Cases')}>КЕЙСЫ</div>
						<div className={styles.link} onClick={() => scrollTo('Contacts')}>КОНТАКТЫ</div>
					</div>
					<div className={styles.openIcon} onClick={handleToggleMenu}></div>
				</div>
			</div>
			<div className={`${styles.openMenu} ${isMenuOpen ? styles.active : ''}`}>
				<div>
					<div>
						<div>
							<div className={styles.openMenuHeader}>
								<div
									className={styles.logo} onClick={() => {
									navigateTo('/')
									handleToggleMenu()
								}}
								>
									<b style={{ color: '#5CC3FD' }}>В</b><b>Р</b>
								</div>
								<div className={styles.iconAndButton}>
									<button className={`${styles.orderBtnMenu} blueButton`} onClick={handleQuestionsClick}>Оставить
										заявку
									</button>
									<div className={styles.closeIcon} onClick={handleToggleMenu}></div>
								</div>
							</div>
							<div className={styles.bodyMenu}>
								<div className={styles.menuLeft}>
									<div className={styles.item} onClick={handleToggleServMenu}>
										УСЛУГИ
										<img
											src='/Pictures/Arrow.svg'
											className={`${styles.arrow} ${menuServ ? styles.rotated : ''}`}
											id='services-arrow'></img>
									</div>
									{menuServ && (
										<div className={styles.servList}>
											<li onClick={() => {navigateTo("/services/software")}}>- Разработка ПО</li>
											<li onClick={() => {navigateTo('/services/web-services')}}>- Разработка веб-сервисов</li>
											<li onClick={() => {navigateTo('/services/mobile-apps')}}>- Разработка мобильных приложений</li>
										</div>
									)}
									<div className={styles.item} onClick={handleToggleProdMenu}>
										ПРОДУКТЫ
										<img
											src='/Pictures/Arrow.svg'
											className={`${styles.arrow} ${menuProd ? styles.rotated : ''}`}
											id='services-arrow'></img>
									</div>
									{menuProd && (
										<div className={styles.prodList}>
											{/* <li>- Крипт.Клауд</li> */}
											<li
												onClick={() => {
													window.open('https://вр-кп.рф')
												}}>- КП-генератор
											</li>
										</div>
									)}
								</div>
								<div className={styles.menuRight}>
									<div
										className={styles.item} onClick={() => {
										navigateTo('/about')
									}}>О КОМПАНИИ
									</div>
									<div className={styles.item} onClick={() => scrollTo('Cases')}>КЕЙСЫ</div>
									<div className={styles.item} onClick={() => scrollTo('Contacts')}>КОНТАКТЫ</div>
									<div className={styles.phoneNumber}>+7 (995) 668-35-84</div>
									<div className={styles.socialLinks}>
										<img
											src='/Pictures/tg-icon-50.svg' onClick={() => {
											window.open('https://t.me/ooo_vebrazrabotka')
										}} />
										<img
											src='/Pictures/vk-icon-50.svg' onClick={() => {
											window.open('https://vk.com/vr_it_ru')
										}} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
export default Header