import styles from '../../common/popup.module.css'
import React, { Dispatch, SetStateAction, useState } from 'react'
import axios from 'axios'
import InputMask from 'react-input-mask'
import { useForm } from 'react-hook-form'

type FormData = {
	name: string;
	phone: string;
	email: string;
	subject: string;
	message: string;
	files: FileList;
	agree: boolean;
};

const QuestionsPopup = (props: { open: boolean, setOpen: Dispatch<SetStateAction<boolean>> }) => {
	const { open, setOpen } = props
	const { register, handleSubmit, formState: { errors }, reset } = useForm<FormData>()
	const [selectedFiles, setSelectedFiles] = useState<File[]>([])
	const token = process.env.REACT_APP_TELEGRAM_TOKEN
	const [agree, setAgree] = useState(false)
	const onSubmit = async (data: FormData) => {
		const formData = new FormData()
		formData.append('Имя', data.name)
		formData.append('Телефон', data.phone)
		formData.append('e-mail', data.email)
		formData.append('Тема обращения', data.subject)
		formData.append('Текст сообщения', data.message)
		// for (let i = 0; i < data.files.length; i++) {
		//     formData.append("files", data.files[i]);
		// }
		try {
			await axios.post(`https://api.telegram.org/bot${token}/sendMessage`, {
				chat_id: '-4227583515',
				text: `💻 Заявка на разработку:\nИмя: ${data.name}\nТелефон: ${data.phone}\ne-mail: ${data.email}\nТема обращения: ${data.subject}\nТекст сообщения: ${data.message}`
			})
			
			// for (let i = 0; i < selectedFiles.length; i++) {
			//     const fileData = new FormData();
			//     fileData.append("chat_id", "-4227583515");
			//     fileData.append("document", selectedFiles[i]);
			//     await axios.post(`https://api.telegram.org/bot${token}/sendDocument`, fileData);
			// }
			
			reset()
			setAgree(false)
			// setSelectedFiles([]);
			alert('Заявка успешно отправлена!')
			setOpen(false)
		} catch (error) {
			console.error('Ошибка отправки:', error)
			alert('Ошибка отправки сообщения!')
		}
	}
	
	const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			const files = Array.from(e.target.files)
			const validTypes = ['image/jpeg', 'image/png', 'application/msword', 'application/vnd.ms-excel', 'application/pdf']
			const maxSize = 24 * 1024 * 1024 // 24 MB in bytes
			let totalSize = 0
			const validFiles = files.filter(file => {
				totalSize += file.size
				return validTypes.includes(file.type) && totalSize <= maxSize
			})
			if (validFiles.length > 3) {
				alert('Максимум 3 файла.')
				setSelectedFiles(validFiles.slice(0, 3))
			} else {
				setSelectedFiles(validFiles)
			}
		}
	}
	const handleModalClick = (event: React.MouseEvent<HTMLDivElement>) => {
		if ((event.target as HTMLDivElement).className === styles.container) {
			setOpen(false)
		}
	}
	return (
		<>
			{open &&
				<div className={styles.container} onClick={handleModalClick}>
					<div className={styles.content}>
						<img src='/Pictures/popup-close.png' className={styles.close} onClick={() => setOpen(false)} />
						<h1>Оставьте заявку</h1>
						<form onSubmit={handleSubmit(onSubmit)}>
							<div className={`${styles.twoColumns} ${errors.name || errors.phone ? styles.errors : ""}`}>
								<div>
									<input placeholder='Как к вам обращаться?'{...register('name', { required: true })} />
									{errors.name && <p className={styles.errorMessage}>Укажите ваше имя</p>}
								</div>
								<div>
									<InputMask
										mask='+7 (999) 999-99-99'
										placeholder='+7 (000) 000-00-00'
										id='phone-number'{...register(
										'phone',
										{ required: true, pattern: /^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/ })} />
									{errors.phone && <p className={styles.errorMessage}>Укажите корректный номер телефона</p>}
								</div>
							</div>
							<div className={`${styles.twoColumns} ${errors.email || errors.subject ? styles.errors : ""}`}>
								<div>
									<input
										placeholder='Ваша почта'
										type='email'{...register('email', { required: true })} />
									{errors.email && <p className={styles.errorMessage}>Укажите адрес электронной почты</p>}
								</div>
								<div>
									<input placeholder='Тема обращения'{...register('subject', { required: true })} />
									{errors.subject && <p className={styles.errorMessage}>Укажите тему вашего обращения</p>}
								</div>
							</div>
							<div className={errors.message ? styles.errors : ""}>
	              <textarea
		              className={styles.message}
		              placeholder='Сообщение'
		              maxLength={500}{...register('message', { required: true })}>
	              </textarea>
								{errors.message &&
									<p className={styles.errorMessage}>Расскажите о вашей идее минимум в нескольких предложениях</p>}
							</div>
							<div className={styles.twoColumns}>
								<div className={styles.ps}>
									<h4>**О чем писать в сообщении</h4>
									<p>
										Расскажите, чем занимается ваша компания, какие задачи должен решать цифровой продукт, на какие
										сроки и бюджет рассчитываете
									</p>
								</div>
								<div className={styles.fileInputWrapper}>
									<input
										type='file'
										className={styles.fileInput}
										id='file-input'
										{...register('files')}
										multiple
										onChange={handleFileChange}
									/>
									<div>
										<label htmlFor='file-input' className={styles.fileInputLabel}>
											<img src='/Pictures/papersclip.svg' />
											<span>Прикрепить файл</span>
										</label>
										<div className={styles.selectedFiles}>
											{selectedFiles.map((file, index) => (
												<div key={index}>{file.name}</div>
											))}
										</div>
									</div>
									<p>**Максимум 3 файла (.jpg, .png, .doc, .xls, .pdf), не более 24 МБ в сумме</p>
								</div>
							</div>
							<div className={styles.twoColumns}>
								<button type='submit' className={agree ? styles.active : ''}>Отправить</button>
								<div className={styles.confidential}>
									<p>Согласен на обработку своих персональных данных в соответствии
										с <span onClick={() => {window.open('https://вр-ит.рф/ПОЛИТИКА_КОНФИДЕНЦИАЛЬНОСТИ.pdf')}}>Политикой конфиденциальности</span>.
									</p>
									<label className={styles.fancyCheckbox}>
										<input
											type='checkbox'
											className={styles.c} {...register('agree', { required: true })} onClick={() => {
											setAgree(!agree)
										}} />
										<div>
											<div><img src='/Pictures/checkmark.png' /></div>
										</div>
									</label>
								</div>
							</div>
						</form>
					</div>
				</div>
			}
		</>
	)
}

export default QuestionsPopup