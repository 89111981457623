import {ICase} from "../types";
import React from "react";
export enum Case {
    Prokrasivoe,
    Gradient,
    SKUD
}
const cases: ICase[] = [
    {
        id: Case.Prokrasivoe,
        title: 'Клиника современной медицины “Медлайн”',
        subtitle: 'Маркетплейс товаров в сфере косметологии',
        tags: [
          "Проектирование интерфейса",
          "UX/UI",
          "ASP.NET API",
          "Signal R Hub",
          "React Ts",
        ],
        link: '/cases/prokrasivoe',
        images: [
          '/Pictures/cases/prokrasivoe/ui1.png',
        ],
    },
    {
        id: Case.Gradient,
        title: 'ПО для программно-аппаратного комплекса  «Алко Инспектор Софт»',
        subtitle: 'Собственное ПО для программно-аппаратного комплекса «Алко Инспектор»',
        tags: [
          'Проектирование интерфейса',
          'UX/UI',
          'ASP.NET API',
          'React Ts',
        ],
        link: '/cases/gradient',
        images: [
          '/Pictures/cases/gradient/ui1.png'
        ],
    },
    {
        id: Case.SKUD,
        title: 'ПО интеграции со СКУД',
        subtitle: 'Корпоративная система для приглашения гостей в офис',
        tags: [
            'Проектирование интерфейса',
            'ASP.NET API',
            'React Ts',
            'Python Flask',
            'Интеграция API',
        ],
        link: '/cases/skud',
        images: [
            '/Pictures/cases/skud/ui1.png'
        ],
    },
];

export default cases