import React, { createContext, Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Route, BrowserRouter as Router, Routes, Navigate } from 'react-router-dom'
import MainPage from './pages/MainPage'
import Prokrasivoe from './pages/cases/Prokrasivoe'
import AboutCompany from './pages/AboutCompany'
import Header from './components/header/Header'
import DebugPage from './pages/debug/Debug'
import Software from './pages/services/Software'
import WebService from './pages/services/WebService'
import MobileApps from './pages/services/MobileApps'
import Gradient from './pages/cases/Gradient'
import Skud from './pages/cases/Skud'
import QuestionsPopup from './components/questions-popup/QuestionsPopup'

export const QuestionsContext = createContext<Dispatch<SetStateAction<boolean>>>(() => {
})

function App() {
	const [isQuestionsModalVisible, setQuestionsModalVisible] = useState(false)
	return (
		<QuestionsContext.Provider value={setQuestionsModalVisible}>
			<div className='App'>
				<Router>
					<Header />
					<div className='main-container'>
						<Routes>
							<Route path='/' element={<MainPage />} />
							<Route path='/about' element={<AboutCompany />} />
							<Route path='/services/software' element={<Software />} />
							<Route path='/services/web-services' element={<WebService />} />
							<Route path='/services/mobile-apps' element={<MobileApps />} />
							<Route path='cases/gradient' element={<Gradient />} />
							<Route path='cases/skud' element={<Skud />} />
							<Route path='cases/prokrasivoe' element={<Prokrasivoe />} />
							<Route path='debug' element={<DebugPage />} />
						</Routes>
					</div>
				</Router>
			</div>
			<QuestionsPopup open={isQuestionsModalVisible} setOpen={setQuestionsModalVisible} />
		</QuestionsContext.Provider>
	)
}

export default App
