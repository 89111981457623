import styles from "../common/commonblock2.module.css"

const WhenNeedSoftware = () => {
    return(
        <div className={styles.container}>
            <h2 className='block-title'>КОГДА НУЖНО <span className="soft">ПО</span></h2>
            <section>
                <div className={styles.checkmark}>
                    <div></div><img src='/Pictures/services/checkmark.svg'/>
                </div>
                <div className={styles.textContainer}>
                    <h3>Расширение компании или значительные изменения в её работе </h3>
                    <p>Стандартное ПО может оказаться неспособным справиться с возросшей нагрузкой или адаптироваться к новым процессам.</p>
                </div>
            </section>
            <section>
                <div className={styles.checkmark}>
                    <div></div><img src='/Pictures/services/checkmark.svg'/>
                </div>
                <div className={styles.textContainer}>
                    <h3>Технологические достижения</h3>
                    <p>Стандартные цифровые решения часто не обеспечивают необходимую функциональность при внедрении технологически передовых процессов.</p>
                </div>
            </section>
            <section>
                <div className={styles.checkmark}>
                    <div></div><img src='/Pictures/services/checkmark.svg'/>
                </div>
                <div className={styles.textContainer}>
                    <h3>Информационная и финансовая безопасность</h3>
                    <p>Разработка ПО на заказ позволяет реализовать надёжные меры безопасности, соответствующие требованиям бизнеса.</p>
                </div>
            </section>
            <section>
                <div className={styles.checkmark}>
                    <div></div><img src='/Pictures/services/checkmark.svg'/>
                </div>
                <div className={styles.textContainer}>
                    <h3>Уникальные потребности компании</h3>
                    <p>Индивидуальное программное обеспечение разрабатывается с учётом специфических требований конкретной компании.</p>
                </div>
            </section>
        </div>
    )
}
export default WhenNeedSoftware